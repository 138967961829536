<template>
  <div class="gui-page">
    <div class="banner">
        <div style="width: 1200px; height: 100%; margin: 0 auto; display: flex; align-items: center;  justify-content: space-between;">
            <img src="https://resources.holowits.com.sg/wiki/wikilogo4.png" alt="">
            <div class="search-bar">
                <i class="el-icon-search search-icon"/>
                <input class="search-input" type="text" :placeholder="$i18n.t('news.searchPlaceholder')" v-model="keyWords" @keyup.enter="search">
                <div class="search-button" @click="search">{{ $i18n.t('news.search') }}</div>
            </div>
        </div>
    </div>
    <div class="content">
        <div class="content-left">
            <ul class="nav-group">
                <li class="nav-group-item" v-for="item in list" :key="item.label">
                    <div class="nav-group-item-label"><span>{{ item.label }}</span></div>
                    <ul class="nav-item">
                        <li :class="{'isActive': active === item2.correspond}" v-for="item2 in item.item" :key="item2.name" @click="jump(item2.correspond)">{{ item2.name }}</li>
                    </ul>
                </li>
            </ul>
        </div>
        <div class="content-right">
            <div class="product-series" v-for="item in series" :key="item.target" :id="item.target">
                <div class="series-title"><span>{{ item.title }}</span></div>
                <div class="series-list">
                    <div class="series-item" v-for="(category, index) in item.category" :key="'category_'+index">
                        <a :href="category.url">
                            <div class="series-item-img">
                                <img :src="category.src" alt="">
                            </div>
                            <div class="series-item-label"><span>{{ category.label }}</span></div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
// import { getUserInfoByToken } from "@/api/userApi";
import db from "@/utils/localstorage";
export default {
name: 'holowitswiki',
data() {
    return {
        active: '',
        list: [
            {
                label: 'Operation Guideline',
                item: [
                    {
                        name: 'AI Camera',
                        correspond: '_01',
                    },
                    {
                        name: 'Video Intelligent Platform',
                        correspond: '_02',
                    },
                    {
                        name: 'IP Camera & Analog Camera',
                        correspond: '_03',
                    },
                    {
                        name: 'NVR & XVR',
                        correspond: '_04',
                    },
                    {
                        name: 'Software',
                        correspond: '_05',
                    },
                    {
                        name: 'Cloud Service',
                        correspond: '_06',
                    },
                    {
                        name: 'Solution',
                        correspond: '_07',
                    },
                    {
                        name: 'Other',
                        correspond: '_08',
                    },
                ]
            },
        ],
        series: [
            {
                title: 'AI Camera',
                target: '_01',
                category: [
                    {
                        label: 'X Series',
                        url: 'https://www.holowits.com/wiki?label=HOLOWITS%20AI%20Camera%20X%20Series%20L3',
                        src: 'https://resources.holowits.com.sg/wiki/x_series.png'
                    },
                    {
                        label: 'M Series',
                        url: 'https://www.holowits.com/wiki?label=HOLOWITS%20AI%20Camera%20M%20Series%20L3',
                        src: 'https://resources.holowits.com.sg/wiki/m4.png'
                    },
                    {
                        label: 'C Series',
                        url: 'https://www.holowits.com/wiki?label=HOLOWITS%20AI%20Camera%20C%20Series%20L3',
                        src: 'https://resources.holowits.com.sg/wiki/c_series.png'
                    },
                    {
                        label: 'D Series',
                        url: 'https://www.holowits.com/wiki?label=HOLOWITS%20AI%20Camera%20D%20Series%20L3',
                        src: 'https://resources.holowits.com.sg/wiki/D_series.png'
                    },
                ]
            },
            {
                title: 'Video Intelligent Platform',
                target: '_02',
                category: [
                    {
                        label: 'HWT-IVS1800-C Series',
                        url: 'https://www.holowits.com/wiki?label=HOLOWITS%20Video%20Intelligent%20Platform%20C%20Series%20L3',
                        src: 'https://resources.holowits.com.sg/wiki/IVSC.png'
                    },
                    {
                        label: 'HWT-IVS1800-D Series',
                        url: 'https://www.holowits.com/wiki?label=HOLOWITS%20Video%20Intelligent%20Platform%20D%20Series%20L3',
                        src: 'https://resources.holowits.com.sg/wiki/IVSD.png'
                    },
                ]
            },
            {
                title: 'IP Camera & Analog Camera',
                target: '_03',
                category: [
                    {
                        label: 'E Series',
                        url: 'https://www.holowits.com/wiki?label=HOLOWITS%20E-Series%20Camera%20L3',
                        src: 'https://resources.holowits.com.sg/wiki/E_series.png'
                    },
                    {
                        label: 'A Series',
                        url: '',
                        src: 'https://resources.holowits.com.sg/wiki/A_series.png'
                    },
                ]
            },
            {
                title: 'NVR & XVR',
                target: '_04',
                category: [
                    {
                        label: 'NVR 600',
                        url: 'https://www.holowits.com/wiki?label=HOLOWITS-NVR600%20L3%20',
                        src: 'https://resources.holowits.com.sg/wiki/NVR6.png'
                    },
                    {
                        label: 'NVR 800',
                        url: 'https://www.holowits.com/wiki?label=HOLOWITS-NVR800%20L3',
                        src: 'https://resources.holowits.com.sg/wiki/NVR800.png'
                    },
                    {
                        label: 'XVR 600',
                        url: 'https://www.holowits.com/wiki?label=HOLOWITS-XVR600%20L3',
                        src: 'https://resources.holowits.com.sg/wiki/XVR6.png'
                    },
                ]
            },
            {
                title: 'Software',
                target: '_05',
                category: [
                    {
                        label: 'HOLOWITS iClient S100',
                        url: 'https://www.holowits.com/wiki?label=HOLOWITS%20iClient%20S100%20L3',
                        src: 'https://resources.holowits.com.sg/wiki/s100.png'
                    },
                    {
                        label: 'HOLOWITS iClient S200',
                        url: 'https://www.holowits.com/wiki?label=HOLOWITS%20S200%20L3',
                        src: 'https://resources.holowits.com.sg/wiki/s200.png'
                    },
                    {
                        label: 'HOLOWITS iClient SDC',
                        url: 'https://www.holowits.com/wiki?label=HOLOWITS%20iClient%20SDC%20L3',
                        src: 'https://resources.holowits.com.sg/wiki/iclientsdc.png'
                    },
                    {
                        label: 'IP Video System Design Tool',
                        url: 'https://www.holowits.com/wiki?label=IP%20Video%20System%20Design%20Tool%20L3',
                        src: 'https://resources.holowits.com.sg/wiki/ipdesigntool.png'
                    },
                    {
                        label: 'Capture Tool',
                        url: 'https://www.holowits.com/wiki?label=HOLOWITS%20Capture%20Tool%20L3',
                        src: 'https://resources.holowits.com.sg/wiki/capturee.png'
                    },
                ]
            },
            {
                title: 'Cloud Service',
                target: '_06',
                category: [
                    {
                        label: 'Cloud Service',
                        url: 'https://www.holowits.com/wiki?label=HOLOWITS%20Cloud%20Service%20L3%20',
                        src: 'https://resources.holowits.com.sg/wiki/cloud.png'
                    },
                ]
            },
            {
                title: 'Solution',
                target: '_07',
                category: [
                    {
                        label: 'Intelligent City',
                        url: 'https://www.holowits.com/wiki?label=HOLOWITS-Intelligent%20City%20Solution%20L3',
                        src: 'https://resources.holowits.com.sg/solution/City-326-160.jpg'
                    },
                    {
                        label: 'Intelligent Campus',
                        url: 'https://www.holowits.com/wiki?label=HOLOWITS-Intelligent%20Campus%20Solution%20L3',
                        src: 'https://resources.holowits.com.sg/solution/326-160c.jpg'
                    },
                    {
                        label: 'Intelligent Transportaion',
                        url: 'https://www.holowits.com/wiki?label=HOLOWITS-Intelligent%20Transportation%20Solution%20L3',
                        src: 'https://resources.holowits.com.sg/solution/Trafficman-326-160.jpg'
                    },
                ]
            },
            {
                title: 'Other',
                target: '_08',
                category: [
                    {
                        label: 'Network Keyboard',
                        url: 'https://www.holowits.com/wiki?label=HOLOWITS-NKB800%20L3',
                        src: 'https://resources.holowits.com.sg/wiki/nk.png'
                    },
                    {
                        label: 'Decoder',
                        url: 'https://www.holowits.com/wiki?label=HOLOWITS-DEC800%20Series%20L3',
                        src: 'https://resources.holowits.com.sg/wiki/deco.png'
                    },
                    {
                        label: 'IPC Tester',
                        url: 'https://www.holowits.com/wiki?label=HOLOWITS-IPC%20Tester%20L3',
                        src: 'https://resources.holowits.com.sg/wiki/20230914112505.png'
                    },
                    // {
                    //     label: 'Accessories',
                    //     url: '',
                    //     src: 'https://resources.holowits.com.sg/wiki/3217.png'
                    // },
                    {
                        label: 'Transmission',
                        url: 'https://www.holowits.com/wiki?label=HOLOWITS-POE%20Switch%20L3',
                        src: 'https://resources.holowits.com.sg/wiki/trans.png'
                    },
                    {
                        label: 'Public Address',
                        url: 'https://www.holowits.com/wiki?label=HOLOWITS-Public%20Address%20Products%20L3',
                        src: 'https://resources.holowits.com.sg/wiki/pa06.png'
                    },
                ]
            },
        ],
        timer: null,
        keyWords: ''
    }
},
created() {
    
},
mounted() {
    this.getUserInfoByToken()
    let name = this.$route.params.label ? this.$route.params.label.replaceAll('%20', ' ') : this.list[0].item[0].name;
    this.active = this.list[0].item.find(item => item.name === name).correspond;
    this.$nextTick(() => {
        this.jump(this.active);
        window.addEventListener('scroll', this.scrollFun)
    })
},
beforeDestroy() {
    window.removeEventListener('scroll', this.scrollFun)
},
methods: {
    search() {
        let label = this.keyWords;
        label = label.replace(/\%/g, '%25');
        label = label.replace(/\#/g, '%23');
        label = label.replace(/\&/g, '%26');
        label = label.replace(/\?/g, '%3F');
        label = label.replace(/\//g, '%2F');
        label = label.replace(/( )/g, '%20');
        label = label.replace(/\+/g, '%2B');
        this.$router.push({
            path: `/wiki-search?keywords=${label}`
        })
    },
    async getUserInfoByToken() {
        let userInfo = db.get('CLIENT_USER', '');
        if (!userInfo) {
            this.$router.push({path: '/home'})
        }
        // if (userInfo) {
        //     const {data: {data, isSuccess}} = await getUserInfoByToken({
        //         id: userInfo.id
        //     })
        //     if (isSuccess) {
        //         if (data.roleId !== '1') {
        //             this.$router.push({path: '/home'})
        //         }
        //     } else {
        //         this.$router.push({path: '/home'})
        //     }
        // } else {
        //     this.$router.push({path: '/home'})
        // }
    },
    jump(id) {
        this.active = id;
        this.$el.querySelector(`#${id}`).scrollIntoView({behavior: "smooth", block: "center", inline: "center"});
    },
    scrollFun(e) {
        this.debounce((e) => this.activeNavNode(e), 100)
    },
    debounce(callback, delay) {
        if (this.timer) {
            clearTimeout(this.timer)
            this.timer = setTimeout(() => { callback() }, delay)
        } else {
            this.timer = setTimeout(() => { callback() }, delay)
        }
    },
    //通过上面的监听滚动，判断每个div距离视口顶部激活左侧对应的导航条
    activeNavNode(e) {
        const nodes = document.getElementsByClassName('product-series')
        for (let i = 0; i < nodes.length; i++) {
            let node = nodes[i];
            // 获取该元素此时相对于视口的顶部的距离，即是元素顶部距离视口屏幕上边的距离
            let nodeY = node.getBoundingClientRect().y
            // console.log(node, nodeY)
            // 当元素距离视口顶部的距离在 [0,200] 之间，设置激活该元素对应左侧的导航标题，这个数字可以按需定义
            if (nodeY >= 200 && nodeY <= 500) {
                this.active = node.id;
                return
            }
        }
    }
}
}
</script>

<style lang="less" scoped>
.gui-page {
    background-color: #f7fafb;
}
.banner {
    height: 160px;
    background-image: url('https://resources.holowits.com.sg/wiki/wiki_banner.png');
    padding-left: 30px;
    img {
        width: 160px;
        height: 160px;
        // margin: 5px 0;
    }
    .search-bar {
        width: 80%;
        max-width: 750px;
        background-color: #FFFFFF;
        height: 50px;
        border-radius: 48px;
        display: flex;
        align-items: center;
        overflow: hidden;
        position: relative;
        z-index: 2;
        box-shadow: 0 4px 8px 4px rgba(0, 0, 0, 0.08);

        .search-icon {
            font-size: 24px;
            margin: 0 12px 0 24px;
            color: #999999;
            position: relative;
            z-index: 10;
        }

        .search-input {
            flex: 1;
            width: 100%;
            height: 50px;
            box-shadow: none;
            outline: none;
            font-size: 18px;
            font-family: "Microsoft Yahei", sans-serif;
            padding-left: 60px;
            margin-left: -55px;
            border-radius: 48px 0 0 48px;
            border: 1px solid transparent;
            position: relative;
            z-index: 3;
            color: #333333;

            &:focus + .search-icon {
                color: #c7000b;
            }
        }

        .search-button {
            cursor: pointer;
            color: #FFFFFF;
            font-size: 16px;
            background-color: var(--themeColor);
            height: 100%;
            width: 80px;
            padding-right: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            user-select: none;

            &:active {
                opacity: .8;
            }
        }
    }
}
.content {
    display: flex;
    width: 1600px;
    margin: 0 auto;
    margin-top: 20px;
    .content-left {
        // background-color: white;
        position: fixed;
        width: 300px;
        // padding: 20px;
        // padding: 20px 0px 20px 20px;
        // margin-right: 20px;
        .nav-group {
            // background-color: white;
            padding: 20px 0px 20px 20px;
            margin-right: 20px;
            .nav-group-item {
                &+.nav-group-item {
                    margin-top: 20px;
                }
                .nav-group-item-label {
                    font-size: 18px;
                    font-weight: 600;
                    margin-bottom: 50px;
                    span {
                        padding-bottom: 25px;
                        border-bottom: 1px solid #c7000b;
                    }
                }
                .nav-item {
                    margin-left: 10px;
                    li {
                        list-style: disc;
                        height: 30px;
                        line-height: 30px;
                        cursor: pointer;
                        padding-left: 5px;
                        transition: all 0.3s;
                        &:hover {
                            color: white;
                            background-color: #c7000b;
                        }
                    }
                    li + li {
                        margin-top: 7px;
                    }
                    .isActive {
                        color: white;
                        background-color: #c7000b;
                    }
                }
            }
        }
    }
    .content-right {
        background-color: white;
        padding: 20px;
        margin-bottom: 100px;
        width: 1300px;
        margin-left: 300px;
        .product-series {
            & + .product-series {
                margin-top: 50px;
            }
            .series-title {
                font-size: 24px;
                border-bottom: 1px solid gray;
            }
            .series-list {
                display: flex;
                flex-wrap: wrap;

                .series-item {
                    position: relative;
                    margin-right: 20px;
                    &:nth-child(4n) {
                        margin-right: 0px;
                    }
                    &:hover {
                        cursor: pointer;
                        color: #c7000b;
                    }

                    width: 200px;
                    // flex-shrink: 0;
                    .series-item-img {
                        width: 100%;
                        img {
                            width: 100%;
                            height: 200px;
                            object-fit: contain;
                        }
                    }
                    .series-item-label {
                        position: absolute;
                        width: 100%;
                        bottom: 10px;
                        // margin-top: -20px;
                        height: 20px;
                        line-height: 20px;
                        text-align: center;
                        z-index: 2;
                    }
                    &:hover .series-item-label span {
                        border-bottom: 1px solid #c7000b;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 991px) {
    .banner {
        &>div {
            width: 100% !important;
            display: block !important;
            .search-bar {
                display: none;
            }
        }
    }
    .content {
        width: 100%;
        display: block;

        .content-left {
            display: none;
        }

        .content-right {
            padding: 20px;
            width: 100%;
            margin-left: 0;

            .series-list {
                justify-content: center;
            }
        }
    }
}
</style>